<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.topics">
			<template #title>Topics</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'TopicsNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="topics" item-key="id" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>

				</template>
				<template v-slot:item.use_for_benchmark="{ item }">
					<v-icon v-if="item.use_for_benchmark" color="success">mdi-check</v-icon>
					<v-icon v-else color="error">mdi-close</v-icon>
				</template>
				<template v-slot:item.use_for_resources="{ item }">
					<v-icon v-if="item.use_for_resources" color="success">mdi-check</v-icon>
					<v-icon v-else color="error">mdi-close</v-icon>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "TopicsIndex",
	computed:{
		topics: sync('topics/topics')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: 'Benchmark',
				align: 'start',
				sortable: true,
				value: 'use_for_benchmark',
			},
			{
				text: 'Resources',
				align: 'start',
				sortable: true,
				value: 'use_for_resources',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.getTopics()
		},
		editItem(data){
			this.$router.push({name:'TopicsEdit', params: {id: data.id}})
		},
		getTopics(){
			this.$api.topics.index()
				.then(response => {
					this.topics = response.data
				})
				.catch(error => {
					console.log(error);
					this.$toast.add(error.message, 'error')
				})
		}
	}
}
</script>